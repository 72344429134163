import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from 'gatsby';
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`On the activity page click on the user icon to open a dropdown with more options.`}</p>
    <br />
    <p>{`Click on "Report problem" to open the contact form.`}</p>
    <Image src="features/contactuser.png" className="w-1/2 my-12 mx-auto" mdxType="Image" />
    <p>{`Fill out the subject and message text box. Click on "Send" when you are finished.`}</p>
    <br />
    <p>{`The user you contacted gets your message along with your name, email address,
and a link to the activity.`}</p>
    <Image src="features/contactuser2.png" className="w-1/2 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features/showconditions" mdxType="Link">
  ← Show conditions
    </Link>
    <Link className="float-right" to="/features/uploadactivities" mdxType="Link">
  Upload trails →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      